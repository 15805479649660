<template>
  <div class="order">order</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Order",
  components: {}
};
</script>
